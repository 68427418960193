import React, { ReactElement, useState, useEffect } from 'react';
import './index.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { NavBarProps } from '../navbar';
import contactUs from 'assets/images/logos/contact-us.svg';
import phoneNumber from 'assets/images/logos/numero-convencional.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { faStore, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  AffiliateBussinesForm,
  MainModal,
  ModalChildProps,
} from 'presentation/components';
import Routes from 'presentation/routes';

const AffiliatedButton = (): ReactElement => {
  const [show, setShow] = React.useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="danger"
        className="btn-block mt-3 title-bryant"
      >
        <FontAwesomeIcon icon={faStore}></FontAwesomeIcon> Afilia tu comercio
      </Button>
      <MainModal
        title="Afilia tu comercio"
        show={show}
        onHide={() => setShow(false)}
        render={(props: ModalChildProps) => (
          <AffiliateBussinesForm onHide={props.onHide} />
        )}
      />
    </>
  );
};

const FooterButton = (props): ReactElement => {
  return (
    <Button variant="danger" as={Link} to={props.url} className="btn-block">
      <div className="text-white d-flex justify-content-center">
        {props.children}
        <span className="text-white text-bryant ml-2 font-weight-bold">
          {props.title}
        </span>
      </div>
    </Button>
  );
};

export const Footer = (props: NavBarProps): ReactElement => {
  const newDate = new Date();
  const year = newDate.getFullYear();
  const facebookUrl = 'https://www.facebook.com/CredexNicaragua';
  const zuFacebookUrl = 'https://www.facebook.com/zucredito';
  const site = process.env.REACT_APP_SITE_TITLE || '';
  const whatsapp = process.env.REACT_APP_WHATSAPP_NUMBER || '';
  const [menuSite, setMenuSite] = useState<any[]>([]);

  useEffect(() => {
    if (site === 'credex') {
      setMenuSite(props.items.filter((itemMenu) => itemMenu.country === 'NI'));
    } else {
      setMenuSite(props.items.filter((itemMenu) => itemMenu.country === 'GT'));
    }
  }, []);

  return (
    <>
      <section className="footer-wrapper">
        <Container className="p-4 footer special-container">
          <Row>
            <Col xs={12} md={4} lg={4} className="border-right-footer mt-3">
              <div className="footer-section mx-auto">
                <h5 className="text-center title-bryant">Mapa del sitio</h5>
                <ul className="list-unstyled p-2">
                  {menuSite.map((itenMenu, index) => {
                    return (
                      <li key={index}>
                        <NavLink
                          to={itenMenu.url}
                          activeClassName="text-danger font-weight-bold"
                          exact={itenMenu.url === '/' ? true : false}
                        >
                          {' '}
                          {itenMenu.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4} lg={4} className="border-right-footer mt-3">
              <div className="footer-section mx-auto text-center">
                <Row>
                  <Col
                    md={12}
                    className={site === 'credex' ? 'text-center' : 'd-none'}
                  >
                    <h5 className="text-center title-bryant">
                      Aplicación Móvil
                    </h5>
                    <FooterButton
                      url={Routes.downloadApp}
                      title={'Nuestra aplicación móvil'}
                    >
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </FooterButton>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="my-5">
                    <h5 className="text-center title-bryant">
                      Afilia tu comercio
                    </h5>
                    <AffiliatedButton />
                  </Col>
                </Row>
                <Row className={site === 'credex' ? '' : 'd-none'}>
                  <Col md={12} className="my-3">
                    <h5 className="text-center title-bryant">
                      Descubre bienes en venta
                    </h5>
                    <Link
                      to="/sales"
                      className="mt-3 btn btn-danger text-white btn-block title-bryant"
                    >
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                      &nbsp;Bienes en venta
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} md={4} lg={4} className="contact-col  mt-3">
              <div className="footer-section text-center mx-auto">
                <Row className="pt-4">
                  <Col xs={4} className={site === 'credex' ? '' : 'd-none'}>
                    <img src={contactUs}></img>
                  </Col>
                  <Col
                    xs={8}
                    className={
                      site === 'credex' ? 'align-self-center' : 'd-none'
                    }
                  >
                    <h5>Contáctanos al</h5>
                  </Col>
                  <Col xs={12} className={site === 'credex' ? '' : 'd-none'}>
                    <img src={phoneNumber}></img>
                  </Col>
                  <Col xs={12} className="text-center social-icons">
                    <h5 className="text-center title-bryant">Síguenos</h5>
                    <a
                      href={site === 'credex' ? facebookUrl : zuFacebookUrl}
                      target="_blank"
                      className="social-icon-link align-self-center"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        className="text-danger"
                      />
                    </a>
                    <a
                      href={whatsapp}
                      target="_blank"
                      className="social-icon-link align-self-center"
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        className="text-danger"
                      />
                    </a>
                    {/* Se ocultan redes sociales mientras se crean en esta plataforma */}
                    {/* <a
                    href={instaUrl}
                    target="_blank"
                    className="social-icon-link align-self-center"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="text-danger"
                    />
                  </a> */}
                    {/* Se ocultan redes sociales mientras se crean en esta plataforma */}
                    {/* <a
                    href={youtubeUrl}
                    target="_blank"
                    className="social-icon-link align-self-center"
                  >
                    <FontAwesomeIcon icon={faYoutube} className="text-danger" />
                  </a> */}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Col lg={12} className="footer-copyright">
          <Row className="footer-copyright">
            <Col xs={12} className="p-2 text-center text-bryant">
              <small>
                © {site} <strong>{year}</strong> - Todos los derechos reservados
              </small>
            </Col>
            <Col xs={12} className="p-2 text-center text-bryant">
              <small>
                {site} es una marca registrada de la empresa{' '}
                <a
                  href={
                    site === 'credex'
                      ? 'https://fid.com.ni/'
                      : 'https://fid.group'
                  }
                  target="_blank"
                  className="text-white"
                >
                  FID, S.A.
                </a>
                {site === 'credex' ? (
                  <span>
                    {' '}
                    , institución regulada por la{' '}
                    <a
                      href="http://www.conami.gob.ni/"
                      target="_blank"
                      className="text-white"
                    >
                      CONAMI.
                    </a>{' '}
                    |{' '}
                    <Link
                      to={Routes.termsAndConditions}
                      className="text-white text-bryant py-3"
                    >
                      Términos y condiciones de uso
                    </Link>
                  </span>
                ) : null}
              </small>
            </Col>
          </Row>
        </Col>
      </section>
    </>
  );
};

export default Footer;
