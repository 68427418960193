export const Routes = {
  root: '/',
  aboutus: '/aboutus',
  products: '/products',
  affiliated: '/affiliated',
  affiliatedDetail: '/affiliateddetail',
  paymentPoints: '/paymentpoints',
  LineaRevolventeyTarjetaPrivada: '/linearevolventeytarjetaprivada',
  tarjetadeCreditoMastercard: '/tarjetacreditomastercard',
  passwordRecovery: '/passwordRecovery',
  customerService: '/customerservice',        
  claimProcess: '/claimprocess',
  creditCalculator: '/calculator',
  dashboard: '/account',
  CalculateInterest: '/calculateinterest',
  Contracts: '/contracts',
  financialeducation: '/financialEducation',
  downloadApp: '/downloadapp',
  Sales: '/sales',
  News: '/News',
  factoringDasboard: '/factoringDashboard',
  wizzardFactoring: '/WizzardFactoring',
  frecuentlyquestions: '/Faqs',
  resetPass: '/resetPass',
  paymentPointsGt: '/payment-points',
  bankGyt: '/bankGyT',
  banRural: '/banRural',
  termsAndConditions: '/TermsAndConditions',
  promotionHistory: '/PromotionHistory',
  factoring: '/factoring',
  inversiones: '/inversions',
  instantCash: '/instantCash',
};

export default Routes;
